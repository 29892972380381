import { authService } from "@vdi/auth-service";
import type { Notification } from "../../types";

type NotificationList = {
  data: Notification[];
  meta: {
    next: string;
  };
};

export const fetchNotificationList = async (
  signal,
): Promise<NotificationList> => {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/v1/notifications?limit=100`;

  const resp = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

  if (!resp.ok) {
    throw new Error("Network response was not ok");
  }

  return resp.json();
};
